import { ajax } from "@/api/request";

// 酒店列表
export const get_hotel_list = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/hotelList',
    data
  });
}

// 酒店信息
export const get_hotel_detail = function(params) {
  return ajax({
    method: 'get',
    url: '/hotel/v1/queryHotel',
    params
  });
}

// 酒店新增
export const add_hotel = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/saveHotel',
    data,
    timeout: 10000000
  });
}

// 酒店编辑
export const edit_hotel = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/updateHotel',
    data
  });
}

// 集团编辑
export const edit_brand = function(data) {
  return ajax({
    method: 'post',
    url: '/brand/v1/updateBrand',
    data
  });
}

// 酒店状态修改
export const update_hotel_status = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/updateHotelStatus',
    data
  });
}

// 集团状态修改
export const update_brand_status = function(data) {
  return ajax({
    method: 'post',
    url: '/brand/v1/updateBrandStatus',
    data
  });
}

// 酒店变更记录列表
export const get_hotel_operate_log = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/operateLogList',
    data
  });
}

// 酒店续期
export const renew_hotel = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/hotelRenew',
    data
  });
}

// 酒店延期
export const delay_hotel = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/hotelDelay',
    data
  });
}

// 酒店续期延期记录
export const hotel_renew_log = function(data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/renewList',
    data
  });
}

// 新增集团
export const bread_addBrand = function(data) {
  return ajax({
    method: 'post',
    url: '/brand/v1/addBrand',
    data
  });
}

// 集团关联酒店列表
export const bread_brandAddHotelList = function(data) {
  return ajax({
    method: 'post',
    url: '/brand/v1/brandAddHotelList',
    data
  });
}

//集团列表
export const bread_brandList = function(data) {
  return ajax({
    method: 'post',
    url: '/brand/v1/brandList',
    data
  });
}

//集团列表
export const bread_brandUpdateBiz = function(data) {
  return ajax({
    method: 'post',
    url: '/brand/v1/getBrandUpdateBiz',
    data
  });
}

// 门店删除
export const delete_hotel = function(data) {
  return ajax({
    method: 'post',
    url: '/clearData/v1/clearHotel',
    params: data,
    showMsg: false
  });
}

// 门店业务数据清除
export const clear_hotel_data = function(data) {
  return ajax({
    method: 'post',
    url: '/clearData/v1/clearHotelBizData',
    params: data
  });
}

// 集团删除
export const delete_group = function(data) {
  return ajax({
    method: 'post',
    url: '/clearData/v1/clearBrand',
    params: data,
    showMsg: false
  });
}

// 集团业务数据清除
export const clear_group_data = function(data) {
  return ajax({
    method: 'post',
    url: '/clearData/v1/clearBrandBizData',
    params: data
  });
}

// 获取美团授权参数
export const get_meituanAuthInfo = function (params) {
  return ajax({
    method: 'get',
    url: '/authorizationInformation',
    params
  });
}
export const get_meituanAuthUrl = function (params) {
  return ajax({
    method: 'get',
    url: '/getBusinessAuth',
    params
  });
}

// 获取美团授权参数
export const get_meiTuanSession = function (params) {
  return ajax({
    method: 'get',
    url: '/getMeiTuanSession',
    params
  });
}

// 获取嗨喵接口 是否重复
export const get_haimiaoIsRepeat = function (data) {
  return ajax({
    method: 'post',
    url: '/hotel/v1/setHaiMiao',
    data
  });
}